import React from "react"

interface IProps {
  size?: number
  className?: string
}

export default function CohubLogo({ size = 201, className }: IProps) {
  const height = (52 / 201) * size
  return (
    <svg
      width={size}
      height={height}
      viewBox="0 0 201 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="3"
        width="75"
        height="49"
      >
        <path d="M0 3.16599H74.919V51.268H0V3.16599Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.807 48.909C52.822 48.909 51.205 42.24 51.205 34.29C51.205 27.891 52.35 20.48 57.807 20.48C62.927 20.48 64.207 27.419 64.207 34.425C64.207 42.442 62.522 48.909 57.807 48.909ZM57.74 18.121C47.162 18.121 40.493 24.927 40.493 34.695C40.493 35.014 40.5 35.33 40.515 35.64C40.606 37.59 40.064 39.537 38.804 41.028C35.609 44.806 31.9 46.956 26.416 46.956C18.196 46.956 12.403 40.892 12.403 26.61C12.403 13.608 17.052 5.99599 25.27 5.99599C31.738 5.99599 35.712 10.779 39.014 21.087H41.843L41.574 3.63799H38.946L37.06 6.33299C34.028 4.71499 30.256 3.16599 24.933 3.16599C10.383 3.16599 -0.0599995 13.204 -0.0599995 27.823C-0.0599995 42.577 10.517 51.268 23.855 51.268C32.507 51.268 38.311 47.127 41.857 41.7C44.463 47.795 50.513 51.268 57.47 51.268C68.99 51.268 74.919 43.856 74.919 34.493C74.919 24.994 68.518 18.121 57.74 18.121Z"
          fill="#FFFFFE"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.624 50.257V48.168C97.568 48.101 98.376 47.966 99.117 47.697C100.061 47.293 100.329 46.215 100.329 44.733V29.71C100.329 25.264 98.443 22.838 96.221 22.838C92.381 22.838 90.291 25.6 89.685 27.15V44.396C89.685 45.878 90.022 46.822 91.033 47.36C91.774 47.831 92.65 48.101 93.525 48.168V50.257H75.336V48.168C76.549 48.101 77.357 47.966 78.3 47.697C79.58 47.293 80.052 46.215 80.052 44.733V7.681C80.052 6.4 79.311 4.514 78.233 3.772C77.425 3.234 74.864 2.896 73.652 2.829V0.808L89.214 0L89.685 0.472V23.646H89.82C92.381 21.154 95.075 18.121 100.869 18.121C107.134 18.121 110.031 22.367 110.031 28.766V44.462C110.031 45.946 110.435 46.888 111.648 47.428C112.591 47.831 113.399 48.101 114.612 48.168V50.257H96.624Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138.364 50.257V45.946H138.162C135.602 48.506 132.974 51.2 127.18 51.2C120.915 51.2 118.356 46.552 118.356 40.219V25.802C118.356 24.118 117.682 23.175 116.604 22.434C115.795 21.895 114.38 21.559 113.167 21.49V19.469L127.72 18.593L128.056 19.065V40.084C128.056 44.531 129.875 46.822 132.031 46.822C135.737 46.822 137.622 44.059 138.296 42.711V25.6C138.162 24.185 137.691 23.039 136.68 22.298C136.006 21.76 134.457 21.625 132.907 21.559V19.469L147.458 18.593L147.93 19.065V43.588C148.065 45.002 148.603 46.08 149.681 46.822C150.49 47.36 151.905 47.495 153.117 47.562V50.257H138.364Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.861 22.434C168.301 22.434 166.885 23.444 165.539 25.264V43.924C166.212 45.946 167.695 48.842 171.198 48.842C175.105 48.842 177.934 43.789 177.934 35.233C177.934 28.766 176.048 22.434 170.861 22.434ZM172.815 51.268C165.74 51.268 163.045 48.842 161.564 47.764L159.017 50.245L155.528 50.257C155.731 47.562 155.905 43.723 155.905 38.871V7.681C155.905 6.265 155.163 4.514 154.085 3.84C153.278 3.301 150.785 2.896 149.573 2.829V0.808L165.068 0L165.539 0.472V22.434H165.74C167.357 20.749 170.658 18.121 174.835 18.121C182.717 18.121 188.578 24.522 188.578 34.83C188.578 44.396 181.707 51.268 172.815 51.268Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.641 11.992C191.34 11.992 188.578 9.298 188.578 5.996C188.578 2.696 191.34 0 194.641 0C197.943 0 200.705 2.696 200.705 5.996C200.705 9.298 197.943 11.992 194.641 11.992Z"
        fill="#5BA857"
      />
    </svg>
  )
}
