import React from "react"
import Container from "../components/Container"

import Layout from "../components/layout"
import CohubLogo from "../components/Logos/CohubLogo"
import SEO from "../components/seo"

const Cohub: React.FC = () => (
  <Layout>
    <SEO title="Cohub" />
    <Container>
      <CohubLogo size={120} className="mb-8" />
      <div className="text-base font-sans text-gray-500">Partner</div>
      <div className="font-mono text-gray-500 text-sm">2014 - Present</div>
      <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-12">
        <div className="font-sans text-sm mt-6 col-span-2">
          <p>
            Cohub began as software that provided small companies with the same
            tools that big companies use. "Enterprise for any size" is the
            tagline. We built features for advanced inventory management,
            omnichannel sales, worldwide shipping from dozens of carriers,
            detailed analytics and reporting, and much more.
          </p>
          <p>
            I worked primarily as a developer and the lead designer, building
            features in Ruby on Rails, React, GraphQL, and TypeScript. I also
            created and implemented Cohub's design system.
          </p>
          <p>
            But I did a lot more than just design and development. I was one of
            two primary salesman, met with customers to help run business
            audits, and was involved in hiring our engineering team.
          </p>
          <p>
            As a result of COVID-19's impact on the global economy, in 2020
            Cohub pivoted to building Shopify apps.
          </p>
          <h3 className="font-medium text-gray-500 font-serif mt-12">
            Cohub Inventory, a Shopify app
          </h3>
          <p>
            We began developing an inventory counting application in 2019 with
            the intent to sell it on Shopify's app marketplace. The app is
            really two apps: one is a webapp that runs inside of Shopify, and
            the other is a companion mobile app for iOS and Android devices.
          </p>
          <p>
            The mobile app allows users to conduct inventory counts with anyone
            else who has a smartphone. Counting inventory normally sucks, so
            being able to divide and conquer and use your phone as a barcode
            scanner is a tremendous time saver.
          </p>
        </div>
        <div className="p-4 bg-gray-900 rounded-lg">
          {/* <div className="font-mono text-gray-200 text-base text-center">
            Tech Stack
          </div> */}
          <div className="text-xs text-gray-600 font-mono">
            Languages/Frameworks
          </div>
          <ul className="list-none text-gray-200 font-sans text-sm mt-4">
            <li>Ruby on Rails</li>
            <li>GraphQL</li>
            <li>React</li>
            <li>TypeScript</li>
            <li>Sidekiq</li>
            <li>Postges</li>
          </ul>
          <div className="text-xs text-gray-600 font-mono">
            Platforms/Services
          </div>
          <ul className="list-none text-gray-200 font-sans text-sm mt-4">
            <li>Heroku</li>
            <li>Digital Ocean</li>
            <li>SendGrid</li>
            <li>Twilio</li>
            <li>Spreedly</li>
            <li>Cayan</li>
            <li>Shopify</li>
          </ul>
        </div>
      </div>
    </Container>
  </Layout>
)

export default Cohub
